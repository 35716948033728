import {action, configure, makeObservable, observable} from 'mobx';

export default class BuyOneClickStore {
    //Начальное состояние модалки
    @observable open = false;
    //Уникальный идентификатор 1C
    @observable id = '';
    //Ошибки сервера
    @observable serverErrorOrdersOneClick = '';
    //Успешный заказ
    @observable ordersOneClickFlag = false;
    //Чек бокс по умолчанию всегда стоит
    @observable checkedPersonal = true;
    //Вешаем класс после отправки формы
    @observable formLoad = '';

    @observable errorOrderMessageOneClick = '';

    @observable valuePaymentMethodOneClick = '';

    @observable loaderOneClick = true;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    /**
     * Открытие модалки "Купить в один клик"
     */
    @action openModal = async (id) => {
        this.loaderOneClick = true;
        this.open = true;
        this.id = id;
        await this.rootStore.productDetailStore.getSizeOneClick(id);
    };

    /**
     * Закрытие модалки "Купить в один клик"
     */
    @action closeModal() {
        try {
            this.open = false;
            this.ordersOneClickFlag = false;
            this.valuePaymentMethodOneClick = '';
            this.rootStore.productDetailStore.activeSize = '';
            this.formLoad = '';
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Применяем скидку если онлайн оплата, для модалки "Купить в один клик"
     */
    @action handleChangePriceOneClickFinal = () => {
        try {
            let priceFinal = this.rootStore.productDetailStore.dataActiveOneClick[0].card_price;
            if (this.valuePaymentMethodOneClick === 'online') {
                priceFinal = Number(priceFinal) - (Math.round(this.rootStore.productDetailStore.onlineDiscountOneClick * Number(priceFinal) / 100));
            } else if (this.valuePaymentMethodOneClick === 'sbp') {
                priceFinal = Number(priceFinal) - (Math.round(this.rootStore.productDetailStore.onlineDiscountSpbOneClick * Number(priceFinal) / 100));
            }
            this.rootStore.productDetailStore.priceOneClickFinal = priceFinal;
            this.rootStore.basketStore.passportControlOneClick();
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Метод оплаты для заказа в один клик
     * @param selectedOption
     */
    @action handleChangePaymentMethodOneClick = (selectedOption) => {
        try {
            this.valuePaymentMethodOneClick = selectedOption.value;
            this.handleChangePriceOneClickFinal();

            if (this.valuePaymentMethodOneClick === 'online' || this.valuePaymentMethodOneClick === 'sbp') {
                this.rootStore.basketStore.textOrderRegistrationOneClick = 'Оплатить';
            } else if (this.valuePaymentMethodOneClick === 'credit') {
                this.rootStore.basketStore.textOrderRegistrationOneClick = 'Оформить в кредит на';
            } else {
                this.rootStore.basketStore.textOrderRegistrationOneClick = 'Оформить заказ на';
            }
        } catch (e) {
            console.log('e', e);
        }
    };
}
