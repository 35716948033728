/**
 * Отправка цели в Яндекс.Метрику
 * @param {string} goal имя цели
 */
const reachGoalYM = (goal) => {
    try {
        let attempts = 0;
        // Максимальное количество попыток
        const maxAttempts = 150;
        const intervalId = setInterval(() => {
            attempts++;
            if (typeof ym === "function" || attempts >= maxAttempts) {
                // Остановить вызов каждую секунду
                clearInterval(intervalId);
                if (typeof ym === "function") {
                    // Вызвать функцию ym
                    return ym(45198306, 'reachGoal', goal);
                } else {
                    // Если ym так и не стало доступной
                    console.log("ym is not a function");
                }
            }
        }, 100);
    } catch (e) {
        console.log('e', e);
    }
};

export default reachGoalYM;
