import {observable, makeObservable, configure, action} from 'mobx';

export default class AddSnackbarsStore {
    // Состояние
    @observable openAddSnackbars = false;
    // Передаваемые параметры
    data = {};

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    // Запуск всплывашки
    @action handleClickAddSnackbars() {
        this.openAddSnackbars = true;
    }

    // Закрытие всплывашки
    @action handleCloseAddSnackbars(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.openAddSnackbars = false;
    }

}
