import {action, makeObservable, observable} from "mobx";

export default class FileManagerStore {
    // Путь к изображению
    @observable pathAddImage = '';
    // Название поля в форме, куда сохраним путь к изображению
    @observable field = '';
    // Название директории (папки)
    @observable directory = '';
    // Путь к файлу
    @observable pathFile = '';
    // Тип сортировки для файлов
    @observable sortFiles = 'time';
    // Расширение файла
    @observable extension = '';
    // Список директорий (папок) файлового менеджера
    @observable directories = [];
    // Состояния директорий (папок) файлового менеджера
    @observable directoryStates = {};
    // Список файлов файлового менеджера
    filesBackend = [];
    @observable files = [];
    // Файловый менеджер в админке
    @observable flagModalFilesManager = false;
    // Модальное окно "Создать папку"
    @observable flagModalCreateFolder = false;
    // Модальное окно "Удалить папку"
    @observable flagModalDeleteFolder = false;
    // Модальное окно "Загрузить файл/файлы"
    @observable flagModalMakeFile = false;
    // Модальное окно "Удалить файл"
    @observable flagModalDeleteFile = false;
    // Статус получения данных для файлового менеджера
    @observable fileManagerLoader = '';
    // Номер страницы (для пагинации файлов)
    @observable page = '';
    // Сколько показывать на странице (для пагинации файлов)
    @observable perPage = '';

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action pathAddImageSet = (src) => {
        this.pathAddImage = src;
        this.closeModalFileManager();
    };

    /**
     * Очистим все данные по ранее добавленным изображениям
     */
    @action clearImages = () => {
        this.pathAddImage = ''; // Путь к изображению
        this.field = ''; // Название поля в форме, куда был записан путь к изображению
    }

    /**
     * Открываем файловый менеджер в админке
     */
    @action openModalFileManager = (field = '') => {
        this.rootStore.adminAllStore.closeModalProfileMenu();
        this.field = field;
        this.fileManagerLoader = ' load';
        this.getFileManager().then(() => this.fileManagerLoader = '');
        this.flagModalFilesManager = true;
    };

    /**
     * Закрываем файловый менеджер в админке
     */
    @action closeModalFileManager = () => {
        this.flagModalFilesManager = false;
    };

    /**
     * Открываем окно "Создать папку"
     */
    @action openModalCreateFolder = (path) => {
        this.directory = path;
        this.flagModalCreateFolder = true;
    };

    /**
     * Закрываем окно "Создать папку"
     */
    @action closeModalCreateFolder = () => {
        this.flagModalCreateFolder = false;
    };

    /**
     * Открываем окно "Удалить папку"
     */
    @action openModalDeleteFolder = (path) => {
        this.directory = path;
        this.flagModalDeleteFolder = true;
    };

    /**
     * Закрываем окно "Удалить папку"
     */
    @action closeModalDeleteFolder = () => {
        this.flagModalDeleteFolder = false;
    };

    /**
     * Открываем окно "Загрузить файл/файлы"
     */
    @action openModalMakeFile = () => {
        this.flagModalMakeFile = true;
    };

    /**
     * Закрываем окно "Загрузить файл/файлы"
     */
    @action closeModalMakeFile = () => {
        this.pathFile = '';
        this.flagModalMakeFile = false;
    };

    /**
     * Открываем окно "Удалить файл"
     * @param pathFile
     */
    @action openModalDeleteFile = (pathFile = '') => {
        this.pathFile = pathFile.replace('storage', 'public');
        this.flagModalDeleteFile = true;
    };

    /**
     * Закрываем окно "Удалить файл"
     */
    @action closeModalDeleteFile = () => {
        this.flagModalDeleteFile = false;
    };

    // Запрос данных для файлового менеджера
    @action getFileManager = async () => {
        try {
            const response = await axios.post(this.rootStore.adminAllStore.prefixAdminPanel + '/file-manager/get-file-manager');
            const {data} = response;
            const {success = {}} = data;
            const {directories = [], directoryStates = {}, files = []} = success;
            this.directories = directories;
            let newDirectoryStates = directoryStates;
            if (this.directory !== '') {
                newDirectoryStates[this.directory] = true;
            }
            this.directoryStates = newDirectoryStates;
            this.files = files;
        } catch (error) {
            console.log('error', error);
        }
    }

    // Запрос файлов для выбранной директории для файлового менеджера
    @action getFiles = async () => {
        try {
            this.fileManagerLoader = ' load';

            const response = await axios.post(this.rootStore.adminAllStore.prefixAdminPanel + '/file-manager/get-files', {
                directory: this.directory,
            });
            const {data} = response;
            const {success = {}} = data;
            const {files = []} = success;
            this.filesBackend = files;
            // this.files = files;
            this.changeFileSorting(this.sortFiles);
            this.fileManagerLoader = '';
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * Сортировка файлов
     * @param sortType
     */
    @action changeFileSorting = (sortType) => {
        try {
            // if (this.filesBackend.length === 0) return;

            this.sortFiles = sortType;
            if (sortType === 'time') {
                this.files = this.filesBackend.sort((a, b) => {
                    if (a.time < b.time) return 1;
                    if (a.time > b.time) return -1;
                    return 0;
                });
            } else {
                this.files = this.filesBackend.sort((a, b) => {
                    if (a.file.toLowerCase() < b.file.toLowerCase()) return -1;
                    if (a.file.toLowerCase() > b.file.toLowerCase()) return 1;
                    return 0;
                });
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * Фильтрация файлов по расширению
     * @param extension
     */
    @action changeFileExtensionFiltering = (extension = '') => {
        try {
            if (this.filesBackend.length === 0) return;

            this.extension = extension;
            if (extension === '') {
                this.files = this.filesBackend;
            }

            if (extension === 'jpg') {
                this.files = this.filesBackend.filter((el) => el.file.includes('.jpg') || el.file.includes('.jpeg') || el.file.includes('.png'))
            }

            if (extension === 'mp4') {
                this.files = this.filesBackend.filter((el) => el.file.includes('.webm') || el.file.includes('.ogg') || el.file.includes('.mp4'))
            }

            if (extension === 'pdf') {
                this.files = this.filesBackend.filter((el) => el.file.includes('.pdf'))
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * Переключение пагинации
     * @param pageNumber
     */
    @action changePagination = (pageNumber = 1) => {
        try {
            this.state.activePage = pageNumber;

            let listCountActive = this.state.listCount * this.state.activePage;
            this.pageNumberMiss = listCountActive - this.state.listCount;
            this.setState({
                filterResult: this.state.data.slice(this.pageNumberMiss, listCountActive),
                start: false
            })
        } catch (error) {
            console.log('error', error);
        }
    }
}
