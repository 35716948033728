import {action, configure, makeObservable, observable} from 'mobx';
import isEmpty from 'is-empty';
import {getProperty, unique} from "@/Helpers/Function";
import {Inertia} from "@inertiajs/inertia";

export default class FilterStore {
    //Количество товаров
    @observable catalogCount = 0;

    @observable loaderShowBtn = false;
    @observable scrollPosition = 0;

    startAllSelFilter = false;

    @observable selectedSortingName = 'По популярности';
    @observable sortCatalogControl = 'hit';
    @observable allChips = [];
    allChipsConcat = [];
    @observable loaderFilter = false;

    //Массивы с уникальными полями
    @observable category = [];
    @observable view = [];
    @observable categoryAndView = [];
    @observable inserts = [];
    @observable insertsColor = [];
    @observable metal = [];
    @observable metalType = [];
    @observable metalAndType = [];
    @observable sex = [];
    @observable size = [];
    @observable weaving = [];
    @observable viewDesign = [];
    @observable thematics = [];
    @observable collection = [];
    @observable sale = [];
    @observable stock = [];

    //Массивы с уникальными полями для контроля
    @observable categoryAndViewControl = [];
    @observable viewControl = [];
    @observable insertsControl = [];
    @observable insertsColorControl = [];
    @observable metalAndTypeControl = [];
    @observable metalTypeControl = [];
    @observable sexControl = [];
    @observable sizeControl = [];
    @observable weavingControl = [];
    @observable viewDesignControl = [];
    @observable thematicsControl = [];
    @observable collectionControl = [];
    @observable saleControl = [];
    @observable stockControl = [];

    //Массив с разбитым url
    urlArr = [];
    @observable urlArrFilter = [];

    //Массив категорий фильтра, заполняется при загрузке
    dataFilterArr = [];
    dataFilterArrControl = [];

    //Отфильтрованный каталог товаров
    @observable catalogFilterResult = [];
    catalogFilterArr = [];

    //Динамик url
    @observable urlDynamic = '';

    categoryFilterRadio = '';
    viewFilterRadio = '';
    metalFilterRadio = '';
    metalTypeFilterRadio = '';

    insertsFilterChecked = [];
    insertsFilterCheckedControl = [];

    insertsColorFilterChecked = [];
    insertsColorFilterCheckedControl = [];

    thematicsFilterChecked = [];
    thematicsFilterCheckedControl = [];

    sizeFilterChecked = [];
    sizeFilterCheckedControl = [];

    sexFilterRadio = '';
    weavingFilterRadio = '';
    viewDesignFilterRadio = '';
    collectionFilterRadio = '';
    saleFilterRadio = '';
    stockFilterRadio = '';

    //Ползунок Цена
    priceAllFilterChecked = '';
    priceAllFilterCheckedControl = [];
    @observable priceControl = false;
    @observable priceCount = 0;
    //Диапазон
    @observable priceValue = [0, 100];
    //Минимальная цена при изменение ползунка
    @observable priceMin = 0;
    //Максимальная цена при изменение ползунка
    @observable priceMax = 0;
    //Минимальная стартовая цена
    priceStart = 0;
    //Максимальная стартовая цена
    priceEnd = 0;

    @observable catalogFilterArrControlLength = '';

    /**
     * Пагинация
     */
        //активная страница
    @observable activePage = 1;
    activePageshow = 0;
    skipShow = 0;
    loaderShowBtnFlag = true;
    //сколько показывать товаров на странице
    @observable catalogListCount = 40;
    catalogListShowStart = 40;
    catalogListShowCount = 40;

    @observable constructorCatalogStartFlag = true;
    @observable prevPropsPathName = '';

    @observable tagOne = [];

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    handleClickLink = () => {
        this.activePage = 1;
        this.catalogListShowCount = this.catalogListShowStart;
        this.scrollPositionChange(0)
    };

    constructorCatalogStart = (catalog = null, allFilter = []) => {
        this.loaderShowBtn = false;
        this.clearFilter();

        //Акции
        this.sale = allFilter.sale ?? [];
        //Категория
        this.category = allFilter.category ?? [];
        //Вид
        this.view = allFilter.view ?? [];
        //Категория с видом
        this.categoryAndView = this.category.map((category) => {
            try {
                return {category, view: this.view.filter((view) => category.valueAlias === view.parent)};
            } catch (e) {
                console.log('e', e);
            }
        });
        //Металл
        this.metal = allFilter.metal ?? [];
        //Тип Металла
        this.metalType = allFilter.metalType ?? [];
        //Металл с типом
        this.metalAndType = this.metal.map((metal) => {
            try {
                return {metal, metalType: this.metalType.filter((metalType) => metal.valueAlias === metalType.parent)};
            } catch (e) {
                console.log('e', e);
            }
        });
        //Вставка
        this.inserts = allFilter.inserts ?? [];
        //Цвет вставок
        this.insertsColor = allFilter.insertsColor ?? [];
        //Пол
        this.sex = allFilter.sex ?? [];
        //Цена
        const priceMinMax = getProperty(allFilter, 'priceMinMax', null)
        if (priceMinMax !== null) {
            this.priceStart = Number(getProperty(priceMinMax, 'price_min', 0));
            this.priceEnd = Number(getProperty(priceMinMax, 'price_max', 0));
            this.priceMin = this.priceStart;
            this.priceMax = this.priceEnd;
        }
        //Размер
        this.size = allFilter.size ?? [];
        //Вид плетения
        this.weaving = allFilter.weaving ?? [];
        //Вид дизайна
        this.viewDesign = allFilter.viewDesign ?? [];
        //Тематика
        this.thematics = allFilter.thematics ?? [];
        //Коллекция
        this.collection = allFilter.collection ?? [];
        // Наличие
        this.stock = allFilter.stock ?? [];

        this.allChipsConcat = this.category
            .concat(this.view)
            .concat(this.inserts)
            .concat(this.insertsColor)
            .concat(this.metal)
            .concat(this.metalType)
            .concat(this.sex)
            .concat(this.size)
            .concat(this.weaving)
            .concat(this.viewDesign)
            .concat(this.thematics)
            .concat(this.collection)
            .concat(this.sale)
            .concat(this.stock);

        this.allFilterProdGet(false, catalog);

        this.loaderFilter = false;
        this.constructorCatalogStartFlag = false;
    };

    @action allFilterProdGet = (pageNumber = false, catalog = {}) => {
        const {
            stocksName = [],
            category = [],
            view = [],
            metal = [],
            metalType = [],
            inserts = [],
            insertsColor = [],
            size = [],
            weaving = [],
            viewDesign = [],
            thematics = [],
            collection = [],
            sex = [],
            priceStartMinMax = {},
            priceMinMax = {},
            stock = [],
        } = catalog;

        this.catalogFilterResult = catalog.products;
        this.catalogCount = catalog.count;
        this.priceCount = catalog.count;
        this.skipShow = catalog.skip;

        /**
         * Заполняем контрольные данные для фильтра
         */
        // Акции
        this.saleControl = Object.values(stocksName).map((el) => el.stocksNameAlias ?? '');
        // Категория
        this.categoryAndViewControl = Object.values(category).map((el) => el.categoryAlias ?? '');
        // Вид
        this.viewControl = Object.values(view).map((el) => el.viewAlias ?? '');
        // Металл
        this.metalAndTypeControl = Object.values(metal).map((el) => el.metalAlias ?? '');
        // Тип металла
        this.metalTypeControl = Object.values(metalType).map((el) => el.metalTypeAlias ?? '');
        // Вставки
        this.insertsControl = Object.values(inserts).map((el) => el.insertsAlias ?? '');
        // Цвет вставки
        this.insertsColorControl = Object.values(insertsColor).map((el) => el.insertsColorAlias ?? '');
        // Размер
        this.sizeControl = Object.values(size).map((el) => el.sizeAlias ?? '');
        // Вид плетения
        this.weavingControl = Object.values(weaving).map((el) => el.weavingAlias ?? '');
        // Вид дизайна
        this.viewDesignControl = Object.values(viewDesign).map((el) => el.viewDesignAlias ?? '');
        // Тематика
        this.thematicsControl = Object.values(thematics).map((el) => el.thematicsAlias ?? '');
        // Коллекция
        this.collectionControl = Object.values(collection).map((el) => el.collectionAlias ?? '');
        // Пол
        this.sexControl = Object.values(sex).map((el) => el.sexAlias ?? '');
        // Прайс
        if (!isEmpty(priceStartMinMax)) {
            this.priceStart = Number(priceStartMinMax.price_min ?? 0);
            this.priceEnd = Number(priceStartMinMax.price_max ?? 0);
            this.priceControl = this.priceStart !== this.priceEnd;
        }
        // Прайс
        if (!isEmpty(priceMinMax)) {
            this.priceMin = Number(priceMinMax.price_min ?? 0);
            this.priceMax = Number(priceMinMax.price_max ?? 0);
        }
        // Наличие в магазинах
        this.stockControl = Object.values(stock).map((el) => el.valueAlias ?? '');

        /**
         * Проверка url
         */
        this.pathnameUrl(location.pathname);

        // Категория
        this.categoryFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'category') return item.valueAlias;
        }));

        this.categoryFilterRadio = this.categoryFilterRadio.length > 0 ? this.categoryFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Вид
        this.viewFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'view') return item.valueAlias;
        }));
        this.viewFilterRadio = this.viewFilterRadio.length > 0 ? this.viewFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Метал
        this.metalFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'metal') return item.valueAlias;
        }));

        this.metalFilterRadio = this.metalFilterRadio.length > 0 ? this.metalFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Тип метала
        this.metalTypeFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'metalType') return item.valueAlias;
        }));
        this.metalTypeFilterRadio = this.metalTypeFilterRadio.length > 0 ? this.metalTypeFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Вставка
        this.insertsFilterCheckedControl = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'inserts') return item.valueAlias;
        }));

        this.insertsFilterChecked = this.insertsFilterCheckedControl.length > 0 ? this.insertsFilterCheckedControl.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : [];

        // Цвет вставки
        this.insertsColorFilterCheckedControl = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'insertsColor') return item.valueAlias;
        }));
        this.insertsColorFilterChecked = this.insertsColorFilterCheckedControl.length > 0 ? this.insertsColorFilterCheckedControl.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : [];

        // Пол
        this.sexFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'sex') return item.valueAlias;
        }));
        this.sexFilterRadio = this.sexFilterRadio.length > 0 ? this.sexFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Наличие в магазинах
        this.stockFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'stock') return item.valueAlias;
        }));
        this.stockFilterRadio = this.stockFilterRadio.length > 0 ? this.stockFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Размер
        this.sizeFilterCheckedControl = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'size') return item.valueAlias;
        })).sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        });
        this.sizeFilterChecked = this.sizeFilterCheckedControl.length > 0 ? this.sizeFilterCheckedControl.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : [];

        // Вид плетения
        this.weavingFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'weaving') return item.valueAlias;
        }));
        this.weavingFilterRadio = this.weavingFilterRadio.length > 0 ? this.weavingFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Вид дизайна
        this.viewDesignFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'viewDesign') return item.valueAlias;
        }));
        this.viewDesignFilterRadio = this.viewDesignFilterRadio.length > 0 ? this.viewDesignFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Тематика
        this.thematicsFilterCheckedControl = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'thematics') return item.valueAlias;
        }));
        this.thematicsFilterChecked = this.thematicsFilterCheckedControl.length > 0 ? this.thematicsFilterCheckedControl.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : [];

        // Коллекция
        this.collectionFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'collection') return item.valueAlias;
        }));
        this.collectionFilterRadio = this.collectionFilterRadio.length > 0 ? this.collectionFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Акции
        this.saleFilterRadio = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'sale') return item.valueAlias;
        }));
        this.saleFilterRadio = this.saleFilterRadio.length > 0 ? this.saleFilterRadio.sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }).join(',') + '/' : '';

        // Цена
        this.priceAllFilterCheckedControl = unique(this.allChips.map((item) => {
            if (item.nameAlias === 'price') return item.valueAlias;
        })).sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        });

        if (this.priceAllFilterCheckedControl.length !== 0) {
            let priceMin = Math.min.apply(null, this.priceAllFilterCheckedControl);
            let priceMax = Math.max.apply(null, this.priceAllFilterCheckedControl);

            this.priceAllFilterChecked = `price-min-${priceMin},price-max-${priceMax}/`;
        } else {
            this.priceAllFilterChecked = '';
        }

        this.urlDynamicGet();
    };

    /**
     * Обрабатываем палзунок прайс
     * @param event
     * @param newValue
     */
    @action changePriceSlider = (event, newValue) => {
        try {
            this.priceMin = (Number(this.priceStart) + (Number(this.priceEnd) - Number(this.priceStart)) / 100 * Number(newValue[0])).toFixed(0);
            this.priceMax = (Number(this.priceStart) + (Number(this.priceEnd) - Number(this.priceStart)) / 100 * Number(newValue[1])).toFixed(0);

            this.priceValue = newValue;

        } catch (e) {
            console.log('e', e);
        }
    };

    changePriceInput = (value, valueName) => {
        try {
            this[valueName] = Math.round(isEmpty(value) ? 0 : Number(value));

            let valWSt = ((this.priceMin - this.priceStart) * 100 / (this.priceEnd - this.priceStart));
            let valWEn = ((this.priceMax - this.priceStart) * 100 / (this.priceEnd - this.priceStart));
            this.priceValue = [valWSt, valWEn];

        } catch (e) {
            console.log('e', e);
        }
    };

    @action changePrice = () => {
        this.priceAllFilterChecked = `price-min-${this.priceMin},price-max-${this.priceMax}/`;
        this.catalogListShowCount = this.catalogListShowStart;
        this.activePage = 1;
        this.catalogSend()
    };

    // /**
    //  * Обрабатываем палзунок прайс при отпускании мышки
    //  */
    // @action sliderOnChangeCommittedPrice = async () => {
    //     const response = await axios.post('/api/products/allFilterProdPrice', {
    //         url: location.pathname,
    //         activePage: this.activePage,
    //         showCount: this.catalogListShowCount,
    //         sort: this.sortCatalogControl,
    //         priceMinControl: this.priceMin,
    //         priceMaxControl: this.priceMax
    //     });
    //
    //     try {
    //         const {success} = response.data;
    //
    //         const {status, count} = success;
    //         if (status) {
    //             this.priceCount = count;
    //         }
    //     } catch (e) {
    //         console.log('e', e);
    //     }
    // };

    /**
     * Разбиваем url в массив
     *
     * @param url
     */
    @action pathnameUrl = (url) => {
        try {
            this.urlArr = Object.values(url.split('/')).sort((a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
            }).join(',').split(',').filter((el) => el !== '');

            let categoryAlias = '', viewAlias = '', metalAlias = '', metalTypeAlias = '';

            this.allChips = this.allChipsConcat.filter((filter) => {
                try {
                    for (let f of this.urlArr) {

                        if (filter.nameAlias === 'price') {
                            if (f === 'price-min-' + f || f === 'price-max-' + f) {
                                return filter;
                            }
                        } else {
                            if (f === filter.valueAlias) {
                                if (filter.nameAlias === 'category') {
                                    categoryAlias = filter.valueAlias;
                                }
                                if (filter.nameAlias === 'view') {
                                    viewAlias = filter.valueAlias;
                                }
                                if (filter.nameAlias === 'metal') {
                                    metalAlias = filter.valueAlias;
                                }
                                if (filter.nameAlias === 'metalType') {
                                    metalTypeAlias = filter.valueAlias;
                                }

                                return filter;
                            }
                        }
                    }
                } catch (e) {
                    console.log('e', e);
                }
            });

            if (categoryAlias === '' && !isEmpty(viewAlias)) {
                unique(this.categoryAndView.map((item) => {
                    for (let el of item.view) {
                        if (el.valueAlias === viewAlias) return item.category;
                    }
                })).map((item) => {
                    this.allChips.push(item);
                })
            }

            if (metalAlias === '' && !isEmpty(metalTypeAlias)) {
                unique(this.metalAndType.map((item) => {
                    for (let el of item.metalType) {
                        if (el.valueAlias === metalTypeAlias) return item.metal;
                    }
                })).map((item) => {
                    this.allChips.push(item);
                })
            }

            let priceMin = '', priceMax = '';
            Object.values(url.split('/')).sort((a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
            }).join(',').split(',').forEach((el) => {
                try {
                    if (el.indexOf('price-min') !== -1) {
                        priceMin = el.split('price-min-').sort((a, b) => {
                            if (a > b) return 1;
                            if (a < b) return -1;
                            return 0;
                        }).join('')
                    }
                    if (el.indexOf('price-max') !== -1) {
                        priceMax = el.split('price-max-').sort((a, b) => {
                            if (a > b) return 1;
                            if (a < b) return -1;
                            return 0;
                        }).join('')
                    }
                } catch (e) {
                    console.log('e', e);
                }
            });

            if (!isEmpty(priceMin) && !isEmpty(priceMax)) {

                this.changePriceInput(priceMin, 'priceMin');
                this.changePriceInput(priceMax, 'priceMax');

                this.allChips.push({
                    id: 0,
                    // name: 'Цена',
                    nameAlias: 'price',
                    value: priceMin,
                    valueAlias: priceMin,
                })
                this.allChips.push({
                    id: 1,
                    // name: 'Цена',
                    nameAlias: 'price',
                    value: priceMax,
                    valueAlias: priceMax,
                })
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Удаление набранного фильтра
     */
    @action handleDeleteChips = (nameAlias, valueAlias) => {
        const {
            insertsFilterCheckedControl,
            insertsColorFilterCheckedControl,
            sizeFilterCheckedControl,
            thematicsFilterCheckedControl
        } = this;

        this.activePage = 1;
        this.catalogListCount = this.catalogListShowStart;
        this.catalogListShowCount = this.catalogListShowStart;

        const removeItemFromArray = (arr, item) => {
            const index = arr.indexOf(item);
            if (index !== -1) {
                arr.splice(index, 1);
                return true;
            }
            return false;
        };
        switch (nameAlias) {
            case 'category':
                this.categoryFilterRadio = '';
                this.viewFilterRadio = '';
                this.sizeFilterChecked = [];
                this.viewDesignFilterRadio = '';
                this.weavingFilterRadio = '';
                break;
            case 'view':
                this.viewFilterRadio = '';
                break;
            case 'inserts':
                removeItemFromArray(insertsFilterCheckedControl, valueAlias);
                this.insertsFilterChecked =
                    insertsFilterCheckedControl.length > 0
                        ? `${insertsFilterCheckedControl
                            .sort((a, b) => a.localeCompare(b))
                            .join(',')}/`
                        : '';
                break;
            case 'insertsColor':
                removeItemFromArray(insertsColorFilterCheckedControl, valueAlias);
                this.insertsColorFilterChecked =
                    insertsColorFilterCheckedControl.length > 0
                        ? `${insertsColorFilterCheckedControl
                            .sort((a, b) => a.localeCompare(b))
                            .join(',')}/`
                        : '';
                break;
            case 'metal':
                this.metalFilterRadio = '';
                this.metalTypeFilterRadio = '';
                break;
            case 'metalType':
                this.metalTypeFilterRadio = '';
                break;
            case 'sex':
                this.sexFilterRadio = '';
                break;
            case 'stock':
                this.stockFilterRadio = '';
                break;
            case 'size':
                removeItemFromArray(sizeFilterCheckedControl, valueAlias);
                this.sizeFilterChecked =
                    sizeFilterCheckedControl.length > 0
                        ? `${sizeFilterCheckedControl
                            .sort((a, b) => a.localeCompare(b))
                            .join(',')}/`
                        : '';
                break;
            case 'weaving':
                this.weavingFilterRadio = '';
                break;
            case 'viewDesign':
                this.viewDesignFilterRadio = '';
                break;
            case 'thematics':
                removeItemFromArray(thematicsFilterCheckedControl, valueAlias);
                this.thematicsFilterChecked =
                    thematicsFilterCheckedControl.length > 0
                        ? `${thematicsFilterCheckedControl
                            .sort((a, b) => a.localeCompare(b))
                            .join(',')}/`
                        : '';
                break;
            case 'collection':
                this.collectionFilterRadio = '';
                break;
            case 'sale':
                this.saleFilterRadio = '';
                break;
            case 'price':
                this.priceAllFilterChecked = [];
                break;
        }

        this.catalogSend();
    };

    /**
     * Очистить фильтр
     */
    @action clearFilter = () => {
        this.categoryFilterRadio = '';
        this.viewFilterRadio = '';
        this.insertsFilterChecked = [];
        this.insertsFilterCheckedControl = [];
        this.insertsColorFilterChecked = [];
        this.insertsColorFilterCheckedControl = [];
        this.metalFilterRadio = '';
        this.metalTypeFilterRadio = '';
        this.sexFilterRadio = '';
        this.sizeFilterChecked = [];
        this.sizeFilterCheckedControl = [];
        this.weavingFilterRadio = '';
        this.viewDesignFilterRadio = '';
        this.thematicsFilterChecked = [];
        this.thematicsFilterCheckedControl = [];
        this.collectionFilterRadio = '';
        this.saleFilterRadio = '';
        this.priceAllFilterChecked = [];
        this.priceMin = this.priceStart;
        this.priceMax = this.priceEnd;
        this.priceValue = [0, 100];
        this.stockFilterRadio = '';
    };

    /**
     * Строим динамический url
     * @returns {string}
     */
    @action urlDynamicGet = () => {
        let url = '/catalog/' +
            this.saleFilterRadio +
            this.categoryFilterRadio +
            this.viewFilterRadio +
            this.insertsFilterChecked +
            this.insertsColorFilterChecked +
            this.metalFilterRadio +
            this.metalTypeFilterRadio +
            this.sexFilterRadio +
            this.sizeFilterChecked +
            this.priceAllFilterChecked +
            this.weavingFilterRadio +
            this.viewDesignFilterRadio +
            this.thematicsFilterChecked +
            this.collectionFilterRadio +
            this.stockFilterRadio;

        if (url.slice(-1) === '/') {
            url = url.substring(0, url.length - 1);
        }

        this.urlDynamic = url;
        return this.urlDynamic;
    };

    @action allFilterProdGetPagination = (pageNumber = false) => {
        this.loaderShowBtnFlag = true;
        this.activePageshow = 0;
        this.catalogListShowCount = this.catalogListShowStart;
        this.activePage = pageNumber;
        this.catalogSend();
    };

    @action showMore = () => {
        this.loaderShowBtn = true;
        if (this.loaderShowBtnFlag) {
            this.activePageshow = this.activePage;
            this.loaderShowBtnFlag = false;
        }

        this.activePage = Number(this.activePage) + 1;
        this.catalogListShowCount = Number(this.catalogListShowCount) + Number(this.catalogListShowStart);
        this.catalogSend();
    };

    @action catalogSend = () => {
        if (this.loaderFilter === false) {
            this.urlDynamicGet();
            Inertia.post(
                this.urlDynamic,
                {
                    url: this.urlDynamic,
                    activePage: this.activePage,
                    skipShow: this.skipShow,
                    activePageshow: this.activePageshow,
                    showCount: this.catalogListShowCount,
                    sort: this.sortCatalogControl
                },
                {
                    preserveScroll: true,
                }
            );
        }
    };


    @action scrollPositionChange = (pageYOffset) => {
        this.scrollPosition = pageYOffset
    }
}
