import {configure, makeObservable} from 'mobx';

export default class JobsStore {
    constructor(rootStore) {
        // makeObservable(this)
        // configure({
        //     enforceActions: "never",
        // })
        // this.rootStore = rootStore;
    }
}
