import {observable, action, makeObservable, configure} from 'mobx';

export default class MobileMenuStore {
    //Начальное состояние модалки
    @observable open = false;
    @observable openRightMenu = false;
    @observable category = false;
    @observable categoryVal = 'Кольца';
    @observable metal = false;

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    /**
     * Открытие мобильного меню
     */
    @action openMobileMenu = () => {
        this.open = true;
    };

    /**
     * Закрытие мобильного меню
     */
    @action closeMobileMenu = () => {
        this.open = false;
    };

    @action categoryOpen = () => {
        this.category = true;
    };

    @action metalOpen = (category) => {
        this.categoryVal = category;
        this.metal = true;
        this.category = false;
    };

    @action prev = () => {
        this.category = false;
    };

    @action prevMetal = () => {
        this.category = true;
        this.metal = false;
    }
}
