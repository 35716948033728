import {observable, makeObservable, configure, action} from 'mobx';

export default class LeftMenuDrawerStore {
    @observable drawerOpen = false;
    @observable loader = true;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    @action closeLeftMenu = () => {
        try {
            this.drawerOpen = false;
            window.scrollTo(0, 0);
        } catch (e) {
            console.log('e', e);
        }

    }

    @action openLeftMenu = () => {
        try {
            this.drawerOpen = true;
        } catch (e) {
            console.log('e', e);
        }
    }
}
