import {action, makeObservable, observable} from 'mobx';
import {Inertia} from "@inertiajs/inertia";

export default class AdminAllStore {
    //
    @observable extension = 'ВСЕ';
    //
    @observable sortFiles = 'time';
    // Путь к изображению
    @observable pathAddImage = '';
    // Название поля в форме, куда сохраним путь к изображению
    @observable field = '';
    //
    @observable typeAdd = '';
    // Префикс админ панели
    prefixAdminPanel = '';
    // Все фильтры
    @observable filters = {};
    // Пагинация списка
    @observable pagination = {'page': 1, 'perPage': 25};
    // Сортировка списка
    @observable sort = {};
    // Поисковой запрос
    @observable searchQuery = '';
    // Тело редактора
    @observable editorBody = null;
    // Элементы фильтра
    @observable chips = [];
    // Модальное окно "Профиль пользователя"
    @observable flagModalProfileMenu = false;
    //
    @observable heightSubtractedState = 48;

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    /**
     * Запоминаем входящие данные
     * @param props
     */
    startMainLayout = (props) => {
        console.log('props', props);
        const {pagination = {}, prefixAdminPanel = '', searchQuery = '', chips = []} = props;
        const {page = 1, perPage = 25} = pagination;

        // Сбрасываем данные по фильтрации
        // if (searchQuery === '') {
        //     delete this.filters.search;
        // }

        // Заполняем поисковой запрос
        this.searchQuery = searchQuery;

        // const searchParams = new URLSearchParams(window.location.search);
        // if (!searchParams.has('filter[search]')) {
        //     this.searchQuery = '';
        // }

        // Устанавливаем данные для пагинации
        this.pagination = {'page': page, 'perPage': perPage};


        // if (!searchParams.has('pagination[page]')) {
        //     this.pagination = {'page': page, 'perPage': perPage};
        // } else {
        //     const pageUrl = Number(searchParams.get('pagination[page]'));
        //     const perPageUrl = Number(searchParams.get('pagination[perPage]'));
        //     console.log('page', page);
        //     console.log('pageUrl', pageUrl);
        //     this.pagination = {'page': pageUrl, 'perPage': perPageUrl};
        // }

        // Запоминаем префикс админ панели
        this.prefixAdminPanel = prefixAdminPanel;

        //
        this.chips = chips;
    }

    @action pathAddImageSet = (src) => {
        this.pathAddImage = src;
    };

    @action typeAddSet = (src) => {
        this.typeAdd = src;
    };

    @action clear = () => {
        this.pathAddImage = '';
        this.field = '';
        this.typeAdd = '';
    }

    /**
     * Очистим все данные по ранее добавленным изображениям
     */
    @action clearImages = () => {
        this.pathAddImage = ''; // Путь к изображению
        this.field = ''; // Название поля в форме, куда был записан путь к изображению
    }

    /**
     *
     */
        // @action parseUrl = () => {
        //     const searchParams = new URLSearchParams(window.location.search);
        //     if (!searchParams.has('filters')) {
        //         return;
        //     }
        //     let searchFilters = JSON.parse(searchParams.get('filters'));
        //     const typesFilters = ['orWhere'];
        //     let filters = searchFilters, chips = [];
        //     // typesFilters.forEach((type) => {
        //     //     if (searchFilters.hasOwnProperty(type)) {
        //     //         searchFilters[type].forEach((el) => {
        //     //             chips.push({'type': type, ...el});
        //     //         });
        //     //     }
        //     // });
        //
        //     this.filters = filters;
        //     // this.chips = chips;
        // }

        // Применяемую фильтрацию
    @action changeSearchFilter = (filterName, filterValue) => {
        // Сбрасываем пагинацию
        this.resetPagination();

        // this.filters[filterName] = filterValue;
        //
        // // Если значение пустое, удаляем фильтр из списка фильтров
        // if (filterValue === '') {
        //     delete this.filters[filterName];
        // }

        this.visit();
    }

    /**
     * Переключаем основные фильтры участвующие в запросе
     * @param filter
     */
    @action changeFilter = (filter) => {
        if (filter.type === 'dateStart' || filter.type === 'dateEnd') {
            if (filter.value === '') {
                delete this.filters[filter.type];
            } else {
                this.filters[filter.type] = filter;
            }
        } else {
            if (this.filters.hasOwnProperty(filter.type)) {
                const index = this.filters[filter.type].findIndex(
                    el => el.field === filter.field && el.value === filter.value
                );

                if (index !== -1) {
                    // Если элемент уже существует, удаляем его
                    this.filters[filter.type].splice(index, 1);
                } else {
                    // Если элемента нет, добавляем его
                    this.filters[filter.type].push(filter);
                }
            } else {
                this.filters[filter.type] = [{
                    'field': filter.field,
                    'label': filter.label,
                    'operator': filter.operator ?? '=',
                    'type': filter.type,
                    'value': filter.value,
                }];
            }
        }
    }

    /**
     * Удаляем основной установленный фильтр участвующий в запросе
     * @param filter
     */
    @action deleteFilter(filter) {
        if (filter.type === 'dateStart' || filter.type === 'dateEnd') {
            delete this.filters[filter.type];
        } else {
            if (this.filters.hasOwnProperty(filter.type)) {
                const index = this.filters[filter.type].findIndex(
                    el => el.field === filter.field && el.value === filter.value
                );

                if (index !== -1) {
                    // Если элемент уже существует, удаляем его
                    this.filters[filter.type].splice(index, 1);
                }
            }
        }

        this.visit();
    }

    /**
     * Удаляем основные фильтры участвующие в запросе
     */
    @action deleteFilters() {
        // Сбрасываем пагинацию
        this.resetPagination();
        // Очищаем фильтры
        this.filters = {};
        // Очищаем чипы
        this.deleteChips();
        // Запрос
        this.visit();
    }

    //
    // @action resetFilter = (arr) => {
    //     arr.forEach((el) => {
    //         delete this.filters[el];
    //     });
    // }

    // Применяем пагинацию
    @action changePagination = (page, perPage) => {
        this.pagination = {'page': page, 'perPage': perPage};

        this.visit();
    }

    // Сбрасываем пагинацию
    @action resetPagination = () => {
        this.pagination = {'page': 1, 'perPage': 25};
    }

    // Применяем сортировку
    @action changeSort = (field) => {
        // Проверяем, содержит ли this.sort свойство 'field' и равно ли оно полю, которое передали
        if (this.sort && this.sort.field === field) {
            // Если содержит 'field' и 'type' === 'DESC', меняем 'type' на 'ASC'
            if (this.sort.type === 'DESC') {
                this.sort.type = 'ASC';
            } else {
                // Иначе, если 'type' === 'ASC', меняем на 'DESC'
                this.sort.type = 'DESC';
            }
        } else {
            // Если не содержит 'field' или 'field' !== field, устанавливаем новое значение с 'type' === 'DESC'
            this.sort = {'field': field, 'type': 'DESC'};
        }

        // Сбрасываем пагинацию
        this.resetPagination();

        this.visit();
    }

    // Делаем запрос на текущий урл с параметрами
    @action visit = () => {
        console.log('this.filters', this.filters);
        Inertia.get(location.pathname, {
            searchFilter: {'search': this.searchQuery},
            filters: JSON.stringify(this.filters),
            // filters: {'search': 'not display'},
            // filters: {
            //     'orWhere': {'users.is_active': 0, 'users.is_active': 2, 'users.is_barcode': 1, 'users.payment_only_online': 1},
            //     // 'orWhere1': {'field': 'users.is_active', 'value': 2}
            // },
            // filters: {
            //     'orWhere': JSON.stringify([
            //         {'field': 'users.is_active', 'value': 0},
            //         {'field': 'users.is_active', 'value': 2},
            //         {'field': 'users.is_barcode', 'value': 1},
            //         {'field': 'users.payment_only_online', 'value': 1},
            //     ])
            // },
            pagination: this.pagination,
            // pagination: {'page': 1, 'perPage': 25},
            sort: this.sort,
            // sort: {'field': 'id', 'type': 'ASC'},
            // name: 'John Doe',
            // email: 'john.doe@example.com',
        });
    }

    /**
     * Переключаем фильтры
     * @param newChip
     */
    @action toggleChip(newChip) {
        if (newChip.type === 'dateStart' || newChip.type === 'dateEnd') {
            if (newChip.value === '') {
                delete this.chips[newChip.type];
            } else {
                this.chips[newChip.type] = newChip;
            }
        } else {
            // Поиск существующего элемента в массиве по какому-то критерию (например, по полю 'field' и 'value')
            const index = this.chips.findIndex(
                chip => chip.field === newChip.field && chip.value === newChip.value
            );

            if (index !== -1) {
                // Если элемент уже существует, удаляем его
                this.chips.splice(index, 1);
            } else {
                // Если элемента нет, добавляем его
                this.chips.push(newChip);
            }
        }
    }

    /**
     * Удаляем 1 установленный фильтр
     * @param newChip
     */
    @action deleteChip(newChip) {
        // Поиск существующего элемента в массиве по какому-то критерию (например, по полю 'value' и 'value')
        const index = this.chips.findIndex(
            chip => chip.field === newChip.field && chip.value === newChip.value
        );

        // Если элемент уже существует, удаляем его
        if (index !== -1) {
            this.chips.splice(index, 1);
        }
    }

    /**
     * Удаляем все выбранные фильтры
     */
    @action deleteChips() {
        this.chips = [];
    }

    /**
     * Сброс всех параметров при переходах по страницам
     */
    @action resetAll() {
        this.filters = {};
        this.pagination = {'page': 1, 'perPage': 25};
        this.sort = {};
        this.chips = [];
        //
        this.heightSubtractedState = 48;
    }

    /**
     * Открываем окно "Создать папку"
     */
    @action openModalProfileMenu = () => {
        this.flagModalProfileMenu = true;
    };

    /**
     * Закрываем окно "Создать папку"
     */
    @action closeModalProfileMenu = () => {
        this.flagModalProfileMenu = false;
    };

    /**
     * Применяем фильтрацию
     * @param el
     */
    @action applyFiltering = (el) => {
        // Сбрасываем пагинацию
        this.resetPagination();
        // Изменяем чипы (выбранные фильтры)
        this.toggleChip(el);
        // Изменяем основные фильтры участвующие в запросе
        this.changeFilter(el);
        // Запрашиваем данные
        this.visit();
    };
}
