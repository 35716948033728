import {configure, makeObservable, observable} from 'mobx';
import React from 'react';

export default class AuthorizationStore {
    // Переключатель телефон или email, по умолчанию телефон
    @observable switchPhoneEmail = false;
    // Переключатель авторизация или регистрация, по умолчанию авторизация
    @observable switchLoginRegister = true;
    // Чек бокс по умолчанию всегда стоит
    @observable checkedPersonal = true;
    // Вешаем класс после отправки формы
    @observable formLoad = '';
    //
    @observable codeAuthorisationFlag = false;
    // Принимаем ошибки сервера
    @observable serverError = '';

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    entranceChange() {
        this.switchLoginRegister = !this.switchLoginRegister;
    }
}
