import {observable, makeObservable, configure, action} from 'mobx';

export default class SubscribeNewsletterStore {
//Начальное состояние модалки
    @observable open = false;

    //Флаг валидации перед отправкой формы
    @observable validModalControl = true;

    //Пишем данные с формы
    @observable email = '';

    //Вешаем класс после отправки формы
    @observable formLoad = 'none';

    //Чек бокс по умолчанию всегда стоит
    @observable checkedPersonal = true;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     *Открытие модалки
     */
    @action openModal() {
        this.open = true;
    }

    /**
     *Закрытие модалки
     */
    @action closeModal() {
        this.open = false;
    }

    /**
     * Проверка email
     * @param email
     */
    @action validModal(email) {
        this.email = email;
    }

    /**
     * Изменение чек бокса
     */
    @action checkedPersonalChange() {
        this.checkedPersonal = !this.checkedPersonal;
    }
}
