// Admin
import AdminAllStore from "@/Stores/Admin/AdminAllStore";
import FileManagerStore from "@/Stores/Admin/FileManagerStore";
// Site
import SubMenuStore from "@/Stores/Header/SubMenuStore";
import SelectCityStore from "@/Stores/Header/SelectCityStore";
import SearchStore from "@/Stores/Header/SearchStore";
import AllStore from "@/Stores/AllStore";
import CountryStore from "@/Stores/Header/CountryStore";
import MobileMenuStore from "@/Stores/Header/MobileMenuStore";
import RightMenuStore from "@/Stores/Header/RightMenuStore";
import FavouritesStore from "@/Stores/Pages/FavouritesStore";
import GiftCertificateStore from "@/Stores/Pages/GiftCertificateStore";
import BasketStore from "@/Stores/Pages/BasketStore";
import ProductDetailStore from "@/Stores/Pages/ProductDetailStore";
import ProfileStore from "@/Stores/Pages/ProfileStore";
import LeftMenuDrawerStore from "@/Stores/Footer/LeftMenuDrawerStore";
import SubscribeNewsletterStore from "@/Stores/Footer/SubscribeNewsletterStore";
import AddSnackbarsStore from "@/Stores/All/AddSnackbarsStore";
import ModalStore from "@/Stores/All/ModalStore";
import QuickViewStore from "@/Stores/All/QuickViewStore";
import FilterStore from "@/Stores/Catalog/FilterStore";
import BuyOneClickStore from "@/Stores/All/BuyOneClickStore";
import PasswordRecoveryStore from "@/Stores/Pages/PasswordRecoveryStore";
import OrdersStore from "@/Stores/Pages/OrdersStore";
import AuthorizationStore from "@/Stores/All/AuthorizationStore";
import CollectionsStore from "@/Stores/Collections/CollectionsStore";
import SoulStore from "@/Stores/Pages/SoulStore";
import MapsStore from "@/Stores/All/MapsStore";
import JobsStore from "@/Stores/Pages/JobsStore";
import BlogStore from "@/Stores/Pages/BlogStore";
import NewsStore from "@/Stores/Pages/NewsStore";

class RootStores {
    constructor() {
        /**
         * Admin
         */
        this.adminAllStore = new AdminAllStore(this);
        this.fileManagerStore = new FileManagerStore(this);

        /**
         * All
         */
        if (typeof localStorage !== 'undefined') {
            this.storage = localStorage;
        } else {
            this.storage = {};
        }

        this.allStore = new AllStore(this);
        this.addSnackbarsStore = new AddSnackbarsStore(this);
        this.modalStore = new ModalStore(this);
        this.quickViewStore = new QuickViewStore(this);
        this.buyOneClickStore = new BuyOneClickStore(this);
        this.authorizationStore = new AuthorizationStore(this);
        this.mapsStore = new MapsStore(this);

        /**
         * Pages Catalog
         */
        this.filterStore = new FilterStore(this);

        /**
         * Collections
         */
        this.collectionsStore = new CollectionsStore(this);

        /**
         * Footer
         */
        this.leftMenuDrawerStore = new LeftMenuDrawerStore(this);
        this.subscribeNewsletterStore = new SubscribeNewsletterStore(this);

        /**
         * Header
         */
        this.subMenuStore = new SubMenuStore(this);
        this.mobileMenuStore = new MobileMenuStore(this);
        this.rightMenuStore = new RightMenuStore(this);
        this.searchStore = new SearchStore(this);
        this.countryStore = new CountryStore(this);
        this.selectCityStore = new SelectCityStore(this);

        /**
         * Pages
         */
        this.basketStore = new BasketStore(this);
        this.blogStore = new BlogStore(this);
        this.favouritesStore = new FavouritesStore(this);
        this.giftCertificateStore = new GiftCertificateStore(this);
        this.jobsStore = new JobsStore(this);
        this.newsStore = new NewsStore(this);
        this.ordersStore = new OrdersStore(this);
        this.passwordRecoveryStore = new PasswordRecoveryStore(this);
        this.productDetailStore = new ProductDetailStore(this);
        this.profileStore = new ProfileStore(this);
        this.soulStore = new SoulStore(this);
    }
}

export default new RootStores();
