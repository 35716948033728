/**
 * Отправка цели в Google Analytics
 * @param {string} goal имя цели
 */
const reachGoalGA = (goal) => {
    try {
        let attempts = 0;
        // Максимальное количество попыток
        const maxAttempts = 150;
        const intervalId = setInterval(() => {
            attempts++;
            if (typeof gtag === "function" || attempts >= maxAttempts) {
                // Остановить вызов каждую секунду
                clearInterval(intervalId);
                if (typeof gtag === "function") {
                    // Вызвать функцию
                    return window.gtag('event', goal, {'parameter_1': 'value_1',});
                } else {
                    // Если gtag так и не стало доступной
                    console.log("gtag is not available");
                }
            }
        }, 100);
    } catch (e) {
        console.log('e', e);
    }
};

export default reachGoalGA;
