import {observable, makeObservable, configure, action} from 'mobx';

export default class CountryStore{
    //Открыто / закрыто окно
    @observable countryChecked = false;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    //Обработка клика в не окна
    @action handleClickAway = () => {
        //Закрытие окна
        this.countryChecked = false
    }

    //Обработка открытия / закрытия окна
    @action switchingCountries = () => {
        this.countryChecked = !this.countryChecked
    }
}
