import isEmpty from 'is-empty'
import moment from 'moment-timezone';

export const GTAG_AW = 'G-2EJ1CSPG2H';

moment.tz.setDefault("Europe/Moscow").updateLocale('ru', {
    months: [
        "января", "февраля", "марта", "апреля", "мая", "июня", "июля",
        "августа", "сентября", "октября", "ноября", "декабря"
    ]
});

/**
 * Получаем ширину экрана
 * @type {function(): number}
 */
const scrollWidth = () => {
    return Math.max(
        document.body.offsetWidth, document.documentElement.offsetWidth
    )
};

/**
 * Все остальное desktop
 * @returns {boolean}
 */
const isDesktop = () => scrollWidth() > 991;

/**
 * Определяем стандартные ноутбуки
 * @returns {boolean|boolean}
 */
const isLarge = () => scrollWidth() >= 1200 && scrollWidth() <= 1366;

/**
 * Определяем средние ноутбуки
 * @returns {boolean}
 */
const isDesktopAll = () => scrollWidth() >= 768 && scrollWidth() <= 1279;

/**
 * Определяем маленькие ноутбуки
 * @returns {boolean|boolean}
 */
const isLaptops = () => scrollWidth() >= 992 && scrollWidth() <= 1199;

/**
 * Определяем планшеты
 * @returns {boolean}
 */
const isTablet = () => scrollWidth() > 767 && scrollWidth() <= 991;

/**
 * Определяем мобильное устройство
 * @returns {boolean}
 */
const isMobile = () => scrollWidth() > 0 && scrollWidth() <= 767;

/**
 * Определяем телефоны
 * @returns {boolean}
 */
const isPhones = () => scrollWidth() > 0 && scrollWidth() < 576;

/**
 * Транслитерация кириллицы в URL
 * @param str
 * @returns {string}
 */
function urlRusLat(str) {
    if (str === undefined) return;
    str = str.toLowerCase().trim(); // все в нижний регистр

    let cyr2latChars = new Array(
        ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'],
        ['д', 'd'], ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
        ['и', 'i'], ['й', 'j'], ['к', 'k'], ['л', 'l'],
        ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
        ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
        ['х', 'h'], ['ц', 'c'], ['ч', 'ch'], ['ш', 'sh'], ['щ', 'sch'],
        ['ъ', ''], ['ы', 'y'], ['ь', ''], ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],

        ['А', 'A'], ['Б', 'B'], ['В', 'V'], ['Г', 'G'],
        ['Д', 'D'], ['Е', 'E'], ['Ё', 'YO'], ['Ж', 'ZH'], ['З', 'Z'],
        ['И', 'I'], ['Й', 'J'], ['К', 'K'], ['Л', 'L'],
        ['М', 'M'], ['Н', 'N'], ['О', 'O'], ['П', 'P'], ['Р', 'R'],
        ['С', 'S'], ['Т', 'T'], ['У', 'U'], ['Ф', 'F'],
        ['Х', 'H'], ['Ц', 'C'], ['Ч', 'CH'], ['Ш', 'SH'], ['Щ', 'SCH'],
        ['Ъ', ''], ['Ы', 'Y'],
        ['Ь', ''],
        ['Э', 'E'],
        ['Ю', 'YU'],
        ['Я', 'YA'],

        ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
        ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
        ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
        ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
        ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
        ['z', 'z'],

        ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'], ['E', 'E'],
        ['F', 'F'], ['G', 'G'], ['H', 'H'], ['I', 'I'], ['J', 'J'], ['K', 'K'],
        ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'], ['P', 'P'],
        ['Q', 'Q'], ['R', 'R'], ['S', 'S'], ['T', 'T'], ['U', 'U'], ['V', 'V'],
        ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],

        [' ', '-'], ['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'],
        ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'],

        ['-', '-'], [',', ','], ['.', '.'], ['=', '='], ['«', ''], ['»', ''],
        ['№', ''], ['Ӏ', ''], ['’', ''], ['ˮ', ''], ['_', '-'], ['\'', ''],
        ['`', ''], ['^', ''], ['<', ''], ['>', ''], ['!', ''], ['é', 'e']
    );

    let newStr = new String();

    for (let i = 0; i < str.length; i++) {

        let ch = str.charAt(i);
        let newCh = '';

        for (let j = 0; j < cyr2latChars.length; j++) {
            if (ch == cyr2latChars[j][0]) {
                newCh = cyr2latChars[j][1];
            }
        }
        // Если найдено совпадение, то добавляется соответствие, если нет - пустая строка
        newStr += newCh;

    }

    // Удаляем повторяющие знаки - Именно на них заменяются пробелы.
    // Так же удаляем символы перевода строки, но это наверное уже лишнее
    return newStr.replace(/[_]{2,}/gim, '_').replace(/\n/gim, '');
}

function toggleClass(el, searchClassName, changeClassName = 'active') {
    let elements = document.querySelectorAll(searchClassName);
    elements.forEach((el) => el.classList.remove('active'));
    el.currentTarget.classList.add(changeClassName);
}

/**
 * Правильные окончания
 * @param number - это число для которого нужно выполнить склонение окончания у существительного
 * @param titles - массив строк с вариантами склонений: ['яблоко', 'яблока', 'яблок'].
 * @returns {*}
 */
function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

/**
 * Получаем куки по ключу
 * @param name
 * @returns {string|undefined}
 */
function getCookie(name = '') {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
}

function getWeek(date) {
    let month = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля",
        "августа", "сентября", "октября", "ноября", "декабря"];
    return month[date.getMonth()];
}

/**
 * Обработка файлов перед отправкой
 * @param files
 * @returns {FormData}
 */
function filesProcessing(files = []) {
    const newFiles = new FormData();
    Object.keys(files).forEach((key) => {
        const file = files[key];
        newFiles.append(key, new Blob([file], {type: file.type}), file.name || 'file');
    });
    return newFiles;
}

/**
 * Безопасное извлечение данных
 *
 * @param obj - объект
 * @param propName - поле
 * @param defaultValue - значение по умолчанию, если что то не так
 * @returns {*|string} - по умолчанию возвращается пустая строка
 */
function getProperty(obj, propName, defaultValue = '') {
    return obj && obj[propName] ? obj[propName] : defaultValue;
}

/**
 * Возвращаем уникальный массив
 * @param arr
 * @returns {Array}
 */
const unique = (arr) => {
    try {
        let result = [];

        for (let str of arr) {
            try {
                if (!result.includes(str) && !isEmpty(str)) {
                    result.push(str);
                }
            } catch (e) {
                console.log('e', e);
            }
        }

        return result;
    } catch (e) {
        console.log('e', e);
    }
};

const getKey = (key, sortCatalogControl) => {
    let p = window.location.search;
    p = p.match(new RegExp(key + '=([^&=]+)'));
    return p ? p[1] : sortCatalogControl;
};

/**
 * Первый символ заглавный
 * @param str
 * @returns {string|*}
 */
function ucFirst(str) {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
}

const optionsLoader = {
    radius: 10,
    scale: 1.00,
    corners: 1,
    color: 'rgba(11,23,19,0.75)',
    opacity: 0.7,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 1,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: true,
    position: 'fixed'
};

/**
 * Возвращает дату доставки в нужном формате
 * @param date
 * @returns {*}
 */
function formatDeliveryDay(date) {
    return moment(date).locale('ru').format('D MMMM');
}

/**
 * Рандомная строка
 * @param length
 * @param charSet
 * @returns {string}
 */
function randomString(length = 10, charSet) {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < length; i++) {
        let randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
}

/**
 * Плавный скрол к элементу
 * @param el
 * @param options
 * @returns {boolean}
 */
function scrollToElement(el = null, options = {}) {
    if (el === null) return false;
    el.scrollIntoView(options);
}


/**
 * Минимальный продукт по цене
 * @param data
 * @returns {string|*}
 */
function getDataProductActive(data) {
    try {
        if (!Array.isArray(data)) {
            return '';
        }

        let min = null;
        const productDataActive = data.filter((el) => {
            if (el.kol !== 0 && (el.price < min || min === null)) {
                min = el.price;
                return el;
            }
        });

        if (productDataActive.length === 0) {
            return '';
        }

        return productDataActive.shift().razmer;

    } catch (e) {
        console.log('e', e);
    }
}

/**
 * Проверять, является ли переменная val строкой
 * @param val
 * @returns {boolean}
 */
function isString(val) {
    return (typeof val === "string" || val instanceof String);
}

/**
 * Возвращает WEBP и исходное изображение
 * @param imagePath
 * @returns {{imgPath: string, imgPathWebp: string}}
 */
function changeImagePath(imagePath, defaultImage) {
    try {
        if (!isString(imagePath)) {
            new Error('This is not a string');
            return {
                imgPath: '',
                imgPathWebp: '',
            };
        }

        let imgPath = '', imgPathWebp = '';
        if (!isEmpty(imagePath)) {
            imgPath = imagePath;
            if (imagePath.includes('png')) {
                imgPathWebp = imagePath.replace('png', 'webp');
            }
            if (imagePath.includes('jpg')) {
                imgPathWebp = imagePath.replace('jpg', 'webp');
            }
            if (imagePath.includes('jpeg')) {
                imgPathWebp = imagePath.replace('jpeg', 'webp');
            }
        } else {
            imgPath = defaultImage.previewPath;
            imgPathWebp = defaultImage.previewPathWebp;
        }

        return {
            imgPath: imgPath,
            imgPathWebp: imgPathWebp,
        };

    } catch (e) {
        console.log('e', e);
    }
}

function clearPhone(phone = '') {
    try {
        let newPhone = phone.replace(/[^0-9]/g, '').substr(1, 10);
        return newPhone;
    } catch (e) {
        console.log('e', e);
    }
}

/**
 * Получить clientID yandex метрики
 * @param ymId
 * @returns {null}
 */
function getClientId(ymId) {
    try {
        let result = null;
        if (!ymId || typeof ym !== "function") {
            console.log("ym is not available");
            return result;
        }
        ym(ymId, 'getClientID', function (clientID) {
            result = clientID;
        });
        return result;
    } catch (e) {
        console.log('e', e);
    }
}

export {
    scrollWidth,
    isDesktop,
    isLarge,
    isDesktopAll,
    isLaptops,
    isTablet,
    isMobile,
    urlRusLat,
    toggleClass,
    declOfNum,
    getCookie,
    getWeek,
    filesProcessing,
    getProperty,
    getKey,
    unique,
    ucFirst,
    optionsLoader,
    formatDeliveryDay,
    randomString,
    scrollToElement,
    getDataProductActive,
    isString,
    changeImagePath,
    clearPhone,
    getClientId,
};
