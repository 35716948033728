import {observable, makeObservable, configure, action} from 'mobx';
import isEmpty from 'is-empty';
import {Inertia} from "@inertiajs/inertia";

export default class SelectCityStore {
    // Начальное состояние модалки
    @observable open = false;

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    /**
     * Открытие модалки
     */
    @action openModal() {
        this.open = true;
    }

    /**
     * Закрытие модалки
     */
    @action closeModal() {
        this.open = false;
        const {basketStore = {}, modalStore = {}} = this.rootStore;
        // Запрос данных для получения информации по срокам доставки
        if (location.pathname === '/go-to-checkout') {
            modalStore.getPvzAndShops();
        } else {
            modalStore.getDeliveryInformation();
        }
        // Очистим адрес после выбора города в модалке выбора pvz or brandShop
        basketStore.deliveryInformationModalAddress = '';
    }

    /**
     * Соглашаемся с определенным городом и закрываем окно на долго
     */
    @action closeConfirmYes = async (geo_lat, geo_lon, city, region, country) => {
        const response = await axios.post('/cityCookieAdd', {
            geo_lat: geo_lat,
            geo_lon: geo_lon,
            city: city,
            region: region,
            country: country,
        });

        try {
            const {success = {}} = response.data;
            const {status = false, msg = ''} = success;
            if (status) {
                localStorage.setItem('confirmCityFlag', true);
                if (location.pathname === '/basket') {
                    // this.rootStore.basketStore.pvzChange();
                    this.rootStore.basketStore.getBasket();
                } else {
                    Inertia.reload()
                }
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Модалка "Выбор города", выбираем город и закрываем окно на долго, статический список
     * @param el
     */
    @action closeConfirmCitySelected = async (el) => {
        try {
            const response = await axios.post('/cityCookieAdd', {
                geo_lat: el.geo_lat,
                geo_lon: el.geo_lon,
                city: el.name,
                region: el.region,
                country: el.country,
            });

            const {success = {}} = response.data;
            const {status = false} = success;
            if (status) {
                if (location.pathname === '/basket') {
                    const {basketStore} = this.rootStore;
                    // basketStore.pvzChange();
                    basketStore.getBasket();
                    localStorage.setItem('confirmCityFlag', true);
                } else {
                    Inertia.reload();
                }
                this.closeModal();
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Модалка "Это ваш город?", выбор города по API
     * @param e
     */
    @action
    async handleChange(e) {
        const response = await axios.post('/cityCookieAdd', {
            geo_lat: e.data.geo_lat,
            geo_lon: e.data.geo_lon,
            city: e.data.city,
            region: e.data.region,
            country: e.data.country,
        });

        try {
            const {success = {}} = response.data
            const {status = false, msg = ''} = success
            if (status) {
                localStorage.setItem('confirmCityFlag', true);
                if (location.pathname === '/basket') {
                    // this.rootStore.basketStore.pvzChange();
                    this.rootStore.basketStore.getBasket();
                } else {
                    Inertia.reload();
                }
                this.closeModal();
            }
        } catch (e) {
            console.log('e', e);
        }
    };
}
