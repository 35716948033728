import {configure, makeObservable, observable} from 'mobx';

export default class OrdersStore {
    // Все заказы
    @observable orders = [];
    // Один заказ
    @observable oneOrder = [];
    // Информация по изделиям в заказе
    @observable oneOrderData = [];
    // Loader
    @observable oneOrderLoader = false;
    // Информация о статусах заказа
    @observable oneOrderStatuses = [];
    // Информация о статусах заказа
    @observable getOrderLoader = '';
    // Информация о статусах заказа
    @observable cancelOrderLoader = '';
    @observable loadOrderApi = false;

    @observable orderMessage = '';

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }
}
