import {observable, action, makeObservable, configure} from 'mobx';
import isEmpty from "is-empty";
import {getProperty} from "@/Helpers/Function";

export default class QuickViewStore {
    // Начальное состояние модалки
    @observable open = false;
    @observable loader = false;
    @observable load = false;
    @observable product = [];
    @observable data = [];
    @observable productDataActive = [];
    @observable quickActiveSize = '';

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Открытие модалки
     */
    @action openModal(article) {
        try {
            this.loader = false;
            this.open = true;
            this.getProductQuickView(article).then(() => this.loader = true);
        } catch (e) {
            console.log('e', e);
        }
    }

    @action dataClickQuick = (size, desiredArray = []) => {
        this.quickActiveSize = size;
        this.rootStore.productDetailStore.activeSize = size;
        // this.productDataActive = this.data.filter((el) => el.razmer === size);
        this.productDataActive = desiredArray.filter((el) => (el.razmer === size));
        this.rootStore.productDetailStore.preOrderProductDataActiveChange(this.productDataActive);
    };

    /**
     * Закрытие модалки
     */
    @action closeModal() {
        this.open = false;
    }

    @action getProductQuickView = async (article) => {
        try {
            // Стучимся на сервак
            const response = await axios.post('/product', {
                article: article,
                type: 'quickView',
                city: this.rootStore.selectCityStore.city
            });

            const {success} = response.data;
            const {data, status} = success;

            if (status) {
                const {product = {}} = data;
                this.product = product;
                const {price, product_data = []} = product;

                let productDataActive = product_data.filter((el) => {
                    if (price === el.price) {
                        return el;
                    }
                });

                if (productDataActive.length !== 0) {
                    this.quickActiveSize = productDataActive[0].razmer;
                } else if (product_data.length !== 0) {
                    this.quickActiveSize = product_data[0].razmer;
                    productDataActive.unshift(product_data[0]);
                }
                this.productDataActive = productDataActive;
                this.rootStore.productDetailStore.preOrderProductDataActiveChange(this.productDataActive);

                this.data = product_data.filter((el) => {
                    try {
                        if (el.kol !== 0 && el.price !== 0) {
                            return el;
                        }
                    } catch (e) {
                        console.log('e', e);
                    }
                });

                // if (this.product.product_data !== undefined) {
                //     this.rootStore.productDetailStore.productData = this.product.product_data;
                //     this.data = this.product.product_data.filter((el) => {
                //         try {
                //             if (el.kol !== 0 && el.price !== 0) {
                //                 return el;
                //             }
                //         } catch (e) {
                //             console.log('e', e);
                //         }
                //     });
                //
                //     this.productDataActive = this.data.filter((el) => {
                //         try {
                //             if (this.product.price !== undefined && el.price !== undefined) {
                //                 if (el.price === this.product.price) {
                //                     return el;
                //                 }
                //             }
                //         } catch (e) {
                //             console.log('e', e);
                //         }
                //     });
                //
                //     if (this.productDataActive.length === 0) {
                //         this.productDataActive = this.data.filter((el, i) => i === 0);
                //     }
                //     if (this.productDataActive.length !== 0) {
                //         this.quickActiveSize = this.productDataActive[0].razmer;
                //     } else if (this.product.product_data.length !== 0) {
                //         this.quickActiveSize = this.product.product_data[0].razmer;
                //     }
                // } else {
                //     this.product.product_data = [];
                //     this.rootStore.productDetailStore.productData = [];
                //     this.productDataActive = [];
                //     this.data = [];
                // }

                // Scanned
                let productsScanned = [], newProductsScanned = [];
                productsScanned = localStorage.getItem('productsScanned') ? localStorage.getItem('productsScanned').split(';') : [];
                newProductsScanned = productsScanned.filter((el) => !isEmpty(el) && String(el) !== String(this.product.id));

                if (newProductsScanned.length > 20) {
                    newProductsScanned = newProductsScanned.slice(0, 20);
                }

                newProductsScanned.unshift(String(this.product.id));
                localStorage.setItem('productsScanned', newProductsScanned.join(';'));
            }

            this.load = true;
        } catch (e) {
            console.log('e', e);
        }
    };

}
