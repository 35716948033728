import {observable, action, makeObservable, configure} from 'mobx';

export default class RightMenuStore {
    // Начальное состояние окна
    @observable open = false;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Открытие окна авторизации - регистрации
     */
    @action openRightMenu() {
        this.open = true;
    }

    /**
     * Закрытие окна авторизации - регистрации
     */
    @action closeRightMenu() {
        this.open = false;
    }
}
